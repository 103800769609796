$maincolour: #c1b089;
$secondcolour: #7c683b;
$logo: '/images/handyman.png';
@mixin clearfix {
    &:after {
      content: "";
      display: table;
      clear: both;
    }
}
